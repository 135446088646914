import {observable, action, computed, reaction, makeAutoObservable, toJS} from "mobx"
import React, { createContext } from "react"
import axios from "axios";
import baseUrl from "./Utility";
import {LineOutlined} from "@ant-design/icons";

class ResourceStore {
    resourceArray: any[];
    selectedArticle: number;
    ModalOpen = false;

    RowsToDisplay: number = 4;
    ColsToDisplay: number = 5;

    editArticle: any | null = null;
    SetArticleForEdit(ar: any) {
        this.editArticle = ar;
    }

    get LoadEditArticle () {
        if (this.editArticle === null) return {}
        return this.editArticle;
    }

    ClearEditArticle () {
        this.editArticle = null
    }

    searchText: string = "";

    constructor() {
        this.resourceArray = []
        this.downloadResources();
        this.selectedArticle = -1;
        makeAutoObservable(this, {setSelectArticle: action, getArticleFromTitle: action});
    }

    get Rows() {
        return Math.ceil(this.resourceArray.length / 5)
    }

    async downloadResources(){
        axios({
            method: 'GET',
            url: `${baseUrl()}/GetAllResources`
        }).then((res) => {
            // @ts-ignore
            this.resourceArray = res.data.map(item => {
                item.Title = item.Title.replaceAll('-', " ");
                return item;
            })
        })
    }

    get PageCounts(){
        return Math.ceil(this.Rows / 10)
    }

    get paginationCount(){
        return (Math.ceil(this.resourceArray.length / 20))
    }

    SetDesktop() {
        console.log("view")
        this.RowsToDisplay = 4;
        this.ColsToDisplay = 5;
    }

    SetMobile() {
        console.log("mobile")
         this.RowsToDisplay = 1;
         this.ColsToDisplay = 6;
    }

    async SetArticleToLatest() {
        if (this.resourceArray.length > 0) {
            this.selectedArticle = this.resourceArray[this.resourceArray.length - 1].Id
        }
    }


    getResource(colId: number, rowId: number, pageId: number) : any | null {
        let filterList = this.resourceArray
        if (this.searchText != "" && this.searchText != null) {
            filterList = filterList.filter(item => {
                return item.Title.toLowerCase().includes(this.searchText.toLowerCase())
            })
        }

        if (colId > this.ColsToDisplay || rowId > this.Rows || pageId > Math.ceil(filterList.length / (this.ColsToDisplay * this.RowsToDisplay))) return null;
        let rowColCount = colId + (rowId * this.RowsToDisplay);
        rowColCount += (pageId - 1) * ( this.ColsToDisplay * this.RowsToDisplay );
        if (rowColCount > filterList.length) return null;
        return filterList[rowColCount];
    }

    setSelectArticle(id: number) {
        this.selectedArticle = id;
    }

    unSetSelectedArticle() {
        this.selectedArticle = -1;
    }

    get SelectArticle(): any{
        const index = this.resourceArray.findIndex(item => {return item.Id === this.selectedArticle})
        if (index < 0) {
            let tempArt = {
                Title: "Issue Loading",
                Article: "Sorry We had an issue loading this article"
            }
            return tempArt;
        }
        return this.resourceArray[index];
    }

    formatForDisplay(str: string) {
        if (str === "" || str === null || str === undefined) return (<p>""</p>);
        return str.split('\n').map((s: string) => {
            if (s.includes("<h3>")) {
                s = s.replace("<h3>", "");
                s = s.replace("</h3>", "")
                return <h3>{s}</h3>
            }
            if (s.includes("<h5>")) {
                s = s.replace("<h5>", "");
                s = s.replace("</h5>", "")
                return <h3>{s}</h3>
            }
            if (s.includes("<point>")) {
                s = s.replace("<point>", "");
                s = s.replace("</point>", "")
                return <p><LineOutlined />{s}</p>
            }
            if (s.includes("<a>")) {
                s = s.replace("<a>", "");

                s = s.replace("</a>", "")
                return <a href={s.split(",")[0]}>{s.split(",")[1]}</a>
            }
            return <p>{s}</p>
        })
    }

    formatForMarketingDisplay(str: string) {
        str = str.substring(0, str.length / 4);
        str += "......";
        return this.formatForDisplay(str);
    }

    getArticleFromTitle(title: string) {
        if (this.resourceArray.length === 0) {
        } else {
            const index = toJS(this.resourceArray).findIndex(i => {return (i.Title.includes(title))})
            if (index < 0) return;
            this.setSelectArticle(this.resourceArray[index].Id);
        }
    }

}

export default createContext(new ResourceStore())
